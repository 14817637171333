import React, { useEffect, useState } from "react";
// import Portada from "./assets/portada.jpg";
import { ReactComponent as AnillosBlack } from "./assets/icono-anillos-black.svg";
import { ReactComponent as Regalo } from "./assets/icono-regalo.svg";
import { ReactComponent as Calendario } from "./assets/icono-calendario-black.svg";
import { ReactComponent as Dresscode } from "./assets/icono-dresscode-white.svg";
import { ReactComponent as Canciones } from "./assets/icono-canciones-black.svg";
import { ReactComponent as Logo9 } from "./assets/NOS-CASAMOS.svg";
import { motion, useAnimation } from "framer-motion";
import CountdownTimer from "./CountdownTimer";
import "./App.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useInView } from "react-intersection-observer";

const AnimatedImage = ({ src, delay }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.1, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden" // Empieza 10px abajo y opacidad en 0
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 70 }, // Estado inicial (oculto).
        visible: {
          opacity: 1,
          y: 0,
          transition: { delay: delay / 1000, duration: 1 }, // Asegúrate de que el delay y la duración sean apropiados.
        },
      }}
      className="col-6 col-md-3 item-galeria"

      // style={{ width: "100%", height: "auto", marginBottom: "20px" }} // Asegúrate de ajustar los estilos como necesites
    >
      {/* <a href={src} data-fancybox="images" data-caption=""> */}
      <img
        style={{ borderRadius: "10px" }}
        className="img-fluid"
        src={src}
        alt=""
      />
      {/* </a> */}
    </motion.div>
  );
};

const Invitacion = () => {
  const images = [
    { src: "./assets/photos/c4.JPG" },
    { src: "./assets/photos/c6.JPG" },
    { src: "./assets/photos/c10.jpeg" },
    { src: "./assets/photos/c9.jpeg" },
    { src: "./assets/photos/c8.jpeg" },
    { src: "./assets/photos/c11.jpeg" },
    { src: "./assets/photos/camifede1.jpeg" },
    { src: "./assets/photos/cf2.JPG" },
  ];
  const [offsetY, setOffsetY] = useState(0);
  const [openCBU, setOpenCBU] = useState(false);

  const controlsAnillos = useAnimation();
  const [refAnillos, inViewAnillos] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.1, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewAnillos) {
      controlsAnillos.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsAnillos, inViewAnillos]);

  const controlsCalendario = useAnimation();
  const [refCalendario, inViewCalendario] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.15, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewCalendario) {
      controlsCalendario.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsCalendario, inViewCalendario]);

  const controlsAsistencia = useAnimation();
  const [refAsistencia, inViewAsistencia] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.15, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewAsistencia) {
      controlsAsistencia.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsAsistencia, inViewAsistencia]);

  const controlsCanciones = useAnimation();
  const [refCanciones, inViewCanciones] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.15, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewCanciones) {
      controlsCanciones.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsCanciones, inViewCanciones]);
  const controlsDresscode = useAnimation();
  const [refDresscode, inViewDresscode] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.15, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewDresscode) {
      controlsDresscode.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsDresscode, inViewDresscode]);

  const controlsRegalo = useAnimation();
  const [refRegalo, inViewRegalo] = useInView({
    triggerOnce: true, // La animación debe ocurrir una vez
    threshold: 0.15, // Personaliza esto según necesites
  });

  useEffect(() => {
    if (inViewRegalo) {
      controlsRegalo.start("visible"); // Inicia la animación cuando la imagen entra en el viewport
    }
  }, [controlsRegalo, inViewRegalo]);

  const handleScroll = () => setOffsetY(window.scrollY);

  const [completedAnimations, setCompletedAnimations] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        style={{
          transform: `translateY(${offsetY * -0.21}px)`,
          visibility: "visible",
          zIndex: "-100",
          position: "fixed",
          top: "0px",
          left: "0px",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <img
          className="parallax-slider"
          src={Portada}
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            objectFit: "cover",
          }}
        /> */}
        <div
          className="parallax-slider"
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            objectFit: "cover",
            backgroundColor: "#f9f5f2",
          }}
        ></div>
      </div>
      <section className="portada parallax-portada d-flex justify-content-center align-items-center">
        <div className="logo-portada fade-in-portada">
          {/* <Logo1 style={{ width: "800px" }} /> */}
          {/* <Logo2 style={{ width: "800px" }} /> */}
          <Logo9 className="final-logo" />
          {/* <Logo3 style={{ width: "800px" }} /> */}
          <div className="scroll-down"></div>
        </div>
      </section>
      <section className="cuenta-regresiva">
        {/* <div className="container"> */}
        <CountdownTimer targetDate={"2024-05-11T19:00:00-03:00"} />
        {/* <!-- Contenedor de cuenta regresiva --> */}
        {/* <p>Listo...</p>
          <p id="reloj">¡Llegó el gran día!</p> */}
        {/* </div> */}
      </section>

      <section
        className="ceremonia-fiesta"
        style={{ backgroundColor: "rgb(249, 245, 242)" }}
      >
        <div className="container">
          <div className="row">
            {/* <!-- Icono --> */}
            <div className="containerAnillos">
              {/* <div className="col-md-12 columna"> */}
              <AnillosBlack
                style={{
                  width: "150px",
                  margin: "15px auto 0 auto",
                }}
              />
              {/* <img
                src="./assets/icono-anillos.svg"
                alt=""
                // className="icon anillos"
              /> */}
            </div>

            {/* <!-- Texto --> */}
            <motion.div
              className="col-md-12 columna no-margin-top"
              ref={refAnillos}
              initial="hidden" // Empieza 10px abajo y opacidad en 0
              animate={controlsAnillos}
              variants={{
                hidden: { opacity: 0, y: 40 }, // Estado inicial (oculto).
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.1, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
                },
              }}
            >
              <h4>ceremonia y fiesta</h4>
              <p>
                Sábado <b style={{ fontWeight: 500 }}>11 de Mayo</b> a las{" "}
                <b style={{ fontWeight: 500 }}>19:00 hs</b> en Espacio Tigre.
                <br></br>
                Recibí debajo las indicaciones para llegar. ¡Te esperamos!
              </p>
              <a
                target="_blank"
                href="https://maps.app.goo.gl/8SZNzWnX6NK7Ny6p9"
                className="btn"
                style={{ marginBottom: "50px" }}
              >
                COMO LLEGAR
              </a>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="galeria">
        <div>
          <div className="container">
            <h4>NOSOTROS</h4>
            <p style={{ textAlign: "center", marginBottom: "40px" }}>
              Cada historia de amor es diferente, pero la nuestra es única!
            </p>
          </div>

          <div className="row" style={{ maxWidth: "1140px" }}>
            {images.map((image, index) => (
              <AnimatedImage key={index} src={image.src} delay={index * 85} />
            ))}
          </div>
        </div>
      </section>

      <section className="cbu">
        <div className="container">
          <Regalo style={{ width: "150px", fill: "white" }} />
          <motion.div
            ref={refRegalo}
            initial="hidden" // Empieza 10px abajo y opacidad en 0
            animate={controlsRegalo}
            variants={{
              hidden: { opacity: 0, y: 60 }, // Estado inicial (oculto).
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
              },
            }}
          >
            <p>
              El mejor regalo es que celebres estos 10 años de amor con
              nosotros.{" "}
            </p>
            <br></br>
            <p>
              De todas formas, si lo deseás podés colaborar con nuestra Luna de
              Miel...
            </p>
            <a
              data-fancybox=""
              data-src="#hidden-cbu"
              href="javascript:;"
              data-options='{"touch" : `${false}`}'
              className="btn btn-alt"
              style={{ marginTop: "25px" }}
              onClick={() => setOpenCBU(!openCBU)}
            >
              Ver Datos Bancarios
            </a>
          </motion.div>

          <Modal
            open={openCBU}
            onClose={() => setOpenCBU(false)}
            center
            classNames={{
              overlay: "customOverlay",
              modal: "customModal",
            }}
          >
            <span>Datos Bancarios</span>
            <p style={{ fontWeight: 600 }}>Cuenta en pesos</p>
            <p>CBU: 0720575088000035429586</p>
            <p>Alias: cami.fede.boda</p>
            <p>Banco Santander</p>
            <p>Titular: Macko Camila Belen</p>
            <p>CUIL: 27.39411441.9</p>

            <p style={{ fontWeight: 600, marginTop: "10px", fontSize: "13px" }}>
              (sino en el salón habrá un cofre)
            </p>
          </Modal>
        </div>
      </section>

      <section className="confirmar-asistencia">
        <div className="container">
          <motion.div
            ref={refAsistencia}
            initial="hidden" // Empieza 10px abajo y opacidad en 0
            animate={controlsAsistencia}
            variants={{
              hidden: { opacity: 0, y: 50 }, // Estado inicial (oculto).
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
              },
            }}
          >
            <h4>CONFIRMACIÓN DE ASISTENCIA</h4>
            <p>
              Esperamos que seas parte de esta gran celebración. ¡Confirmanos tu
              asistencia!
            </p>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSc8G7hRBErMyglGGk2Q0l5JZf1XzLulukHpfLNOCzB-OjAhww/viewform?pli=1"
              class="btn"
            >
              Confirmar asistencia
            </a>
          </motion.div>

          <br></br>

          <motion.div
            ref={refCalendario}
            initial="hidden" // Empieza 10px abajo y opacidad en 0
            animate={controlsCalendario}
            variants={{
              hidden: { opacity: 0, y: 50 }, // Estado inicial (oculto).
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.5, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
              },
            }}
          >
            <Calendario style={{ width: "150px" }} />

            <p>¡Agendá la fecha en tu calendario!</p>
            <a
              target="_blank"
              href="https://calendar.google.com/calendar/render?action=TEMPLATE&amp;dates=20240511T220000Z%2F20240512T070000Z&amp;text=LA%20BODA%20DEL%20AÑO%20%23Cami%26Fede&location=Espacio%20Tigre"
              class="btn"
            >
              AGENDAR EVENTO
            </a>
          </motion.div>
        </div>
      </section>

      <section className="dresscode">
        <div className="container animated divDressCode fadeInUp">
          <Dresscode style={{ width: "150px" }} />

          <motion.div
            ref={refDresscode}
            initial="hidden" // Empieza 10px abajo y opacidad en 0
            animate={controlsDresscode}
            variants={{
              hidden: { opacity: 0, y: 40 }, // Estado inicial (oculto).
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.2, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
              },
            }}
          >
            <h4 style={{ marginTop: "10px" }}>DRESS CODE</h4>
            <p>Vestimenta formal, elegante</p>
          </motion.div>
        </div>
      </section>

      <section className="canciones">
        <div className="container">
          <Canciones style={{ width: "150px" }} />

          <br></br>

          <motion.div
            ref={refCanciones}
            initial="hidden" // Empieza 10px abajo y opacidad en 0
            animate={controlsCanciones}
            variants={{
              hidden: { opacity: 0, y: 40 }, // Estado inicial (oculto).
              visible: {
                opacity: 1,
                y: 0,
                transition: { delay: 0.2, duration: 0.4 }, // Asegúrate de que el delay y la duración sean apropiados.
              },
            }}
          >
            <h4>¿QUÉ CANCIONES NO PUEDEN FALTAR?</h4>

            <p>
              ¡Ayudanos sugiriendo las canciones que pensás que no pueden faltar
              en la fiesta!
            </p>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeqWNiYuukTNauCtnmtqwNg1DXTn53weoKff2XY-6HZeDcSdw/viewform"
              className="btn"
            >
              Sugerir canción
            </a>
          </motion.div>
        </div>
      </section>

      <section class="gracias">
        <div class="container">
          <p>¡Gracias por acompañarnos en este momento tan importante!</p>
        </div>
      </section>
    </>
  );
};

export default Invitacion;
