import React, { useState, useEffect } from 'react';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

interface CountdownTimerProps {
    targetDate: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<TimeLeft>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, targetDate]);

    const difference = +new Date(targetDate) - +new Date();
    function calculateTimeLeft(targetDate: string): TimeLeft {
        let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    return (
        <div className='container'>

            {difference <= 0 ? (

                <div style={{color: 'white', height: '100px'}}>
<p className="m-3">Listo...</p>
<h5 className="m-3">¡Llegó el gran día!</h5>
</div>

)

:

(
        <div style={{height: '200px'}}>
                <p className="m-3">Nos encontramos en</p>
                <div style={{display: 'flex', justifyContent: 'center', margin: '0 auto', marginTop: '30px'}}>
                    <div className="col-md-1 col-2 d-flex flex-column justify-content-center p-0">
                        <p className="num_cuenta" id="dias">{timeLeft.days}</p>
                        <p className="text_cuenta">días</p>
                    </div>
                    <p className="num_cuenta">:</p>
                            <div className="col-md-1 col-2 d-flex flex-column justify-content-center p-0">
                        <p className="num_cuenta" id="dias">{timeLeft.hours}</p>
                        <p className="text_cuenta">hs</p>
                    </div>
                    <p className="num_cuenta">:</p>
                            <div className="col-md-1 col-2 d-flex flex-column justify-content-center p-0">
                        <p className="num_cuenta" id="dias">{timeLeft.minutes}</p>
                        <p className="text_cuenta">min</p>
                    </div>
                    <p className="num_cuenta">:</p>
                            <div className="col-md-1 col-2 d-flex flex-column justify-content-center p-0">
                        <p className="num_cuenta" id="dias">{timeLeft.seconds}</p>
                        <p className="text_cuenta">seg</p>
                    </div>
                </div>
        </div>
    )}
    </div>
    )
};

export default CountdownTimer;