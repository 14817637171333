import React from 'react';
import './App.css';
import './assets/stylesWeb.css'
import './funciones'
import Invitacion from './Invitacion';



function App() {
  return (
    <div className="App">
      <Invitacion />
    </div>
  );
}

export default App;
